import Button from 'antd/es/button';
import Result from 'antd/es/result';
import Spin from 'antd/es/spin';
import React, { useContext, useEffect } from 'react'
import getDefaultLoginRoutesForRole from '../../Routes/DefaultLoginRoutesForRole';
import { getRoleFromAuth } from '../../../utils/commonUtils';
import debounce from 'lodash/debounce';
import { EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router';
import { LoginByRole } from '../../../utils/manageUserData';
import { USER_TYPES } from '../../Constants/ApplicationConstants';
import { ADB2C_WORKFLOW_AUTHORITY_URI, ADB2C_WORKFLOW_FORGOT_PASSWORD, ADB2C_AGENT_LOGOUT_REDIRECT_URL, ADB2C_ADMIN_LOGOUT_REDIRECT_URL } from '../../Constants/EnvironmentVariableProvider';
import UserContext from '../../Contexts/UserContext';
import { RoutesLookup } from '../../Routes/RoutesLookup';

function LoginRedirect() {
    const userType = window.location.pathname === RoutesLookup.ADMIN_LOGIN ? USER_TYPES.ADMIN : USER_TYPES.AGENT

    const { auth, setAuth } = useContext(UserContext);
    const { instance: msalInstance } = useMsal();
    const navigate = useNavigate();

    const addEventCallback = () => {
        msalInstance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_SUCCESS &&
                (event.payload && event.payload['idToken'])) {
                const account = event.payload['account']
                localStorage.setItem('account', JSON.stringify(account))
                msalInstance.setActiveAccount(account)
                LoginByRole(
                    event.payload['idToken'],
                    setAuth,
                    navigate,
                    msalInstance,
                    userType
                );
            } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
                setAuth({})
            }
            else if (
                event.eventType === EventType.LOGIN_FAILURE &&
                event.error &&
                event.error['errorCode'] === "access_denied" &&
                event.error['errorMessage'].includes("AADB2C90118")
            ) {
                const passwordResetAuthority = ADB2C_WORKFLOW_AUTHORITY_URI + ADB2C_WORKFLOW_FORGOT_PASSWORD;
                let logoutRedirectUri = ADB2C_AGENT_LOGOUT_REDIRECT_URL
                if (userType === USER_TYPES.ADMIN) {
                    logoutRedirectUri = ADB2C_ADMIN_LOGOUT_REDIRECT_URL
                } else if (userType === USER_TYPES.AGENT) {
                    logoutRedirectUri = ADB2C_AGENT_LOGOUT_REDIRECT_URL
                }
                msalInstance.loginRedirect({
                    scopes: [],
                    authority: passwordResetAuthority,
                    redirectUri: logoutRedirectUri,
                });
            } else if (
                event.eventType === EventType.LOGIN_FAILURE ||
                (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE &&
                    (event.error && event.error['errorCode'] === "user_cancelled"))
            ) {
                loginCancelled();
            }
        });
    }

    const loginCancelled = debounce(function () {
        msalInstance.logout();
    }, 1500);

    useEffect(() => {
        addEventCallback();
        if (auth.token) {
            navigate(getDefaultLoginRoutesForRole(getRoleFromAuth(auth)))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Result
                style={{ marginTop: 100 }}
                icon={<Spin size="large" />}
                title="Logging In"
                subTitle="Please wait as we log you in..."
            />
            <div className="flex justify-center items-center flex-col w-full">
                <p className=" ">
                    If this page does not redirect click this button to refresh
                </p>
                <Button
                    type="primary"
                    style={{
                        width: "299px",
                        marginTop: "20px",
                    }}
                    onClick={() => window.location.reload()}
                >
                    Refresh
                </Button>
            </div>
        </>
    );
}

export default LoginRedirect